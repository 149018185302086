<template>
    <!-- Refine search form (mobile) -->
    <div class="refinesearch refinesearch--mobile">
        <div class="refineform" data-id="refineform">
            <div class="menutoggle open" data-id="closerefine">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <h6>Refine Search</h6>

            <form action="javascript:;">
                <div class="refineform__carcontainer" data-id="search__carcontainer">

                    <div class="refineform__car" data-class="search__car">
                        <div class="refineform__or refineform__or--hidden">Or</div>
                        <div class="refineform__row refineform__row--desktop">
                            <div class="refineform__col refineform__col--full make-container">
                                <treeselect
                                    :limit="3"
                                    :searchable="false"
                                    :multiple="true"
                                    :options="makes"
                                    :load-options="loadOptions"
                                    :auto-load-root-options="false"
                                    placeholder="Select a Make"
                                    v-model="selectedMakes"
                                    v-on:select="setCurFilter('makes');blockAction('make', 'open')"
                                    v-on:input="search();blockAction('make', 'open')"
                                    v-on:open="blockAction('make', 'open')"
                                    v-on:close="blockAction('make', 'close')"
                                >
                                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                                </treeselect>
                            </div>
                            <div class="refineform__col refineform__col--full model-container">
                                <treeselect
                                    :limit="3"
                                    :searchable="false"
                                    :multiple="true"
                                    :options="makeModels"
                                    :load-options="loadOptions"
                                    placeholder="Select a Model"
                                    :auto-load-root-options="false"
                                    v-model="selectedModels"
                                    v-on:select="setCurFilter('models');blockAction('model', 'open')"
                                    v-on:input="search();blockAction('model', 'open')"
                                    v-on:open="blockAction('model', 'open')"
                                    v-on:close="blockAction('model', 'close')"
                                >
                                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                                </treeselect>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>

                <div class="refineform__togglelabels">
                    <label for="monthlyprice">Cash Price</label>
                    <label for="monthlyprice">Monthly Price</label>
                </div>
                <label class="toggleswitch toggleswitch--light">
                    <toggle-button
                        id="monthlyprice"
                        v-model="monthlyPriceSwitch"
                        :margin=0
                        :sync="true"
                    ></toggle-button>
                </label>
                <div v-show="monthlyPriceSwitch" >
                    <div class="form-group">
                        <treeselect
                            :searchable="false"
                            :multiple="false"
                            :options="financeDeposits"
                            :load-options="loadOptions"
                            placeholder="Select a Deposit / Trade-In Value"
                            :auto-load-root-options="false"
                            :defaultExpandLevel="1"
                            v-model="selectedFinanceDeposit"
                            v-on:select="setCurFilter('price')"
                            v-on:input="search()"
                        >
                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                        </treeselect>
                    </div>
                    <div class="form-group">
                        <treeselect
                            :searchable="false"
                            :multiple="false"
                            :options="monthlyPricesFrom"
                            :load-options="loadOptions"
                            placeholder="Per Month: Min"
                            :auto-load-root-options="false"
                            :defaultExpandLevel="1"
                            v-model="selectedMonthlyPricesFrom"
                            v-on:select="setCurFilter('price')"
                            v-on:input="search()"
                        >
                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                        </treeselect>
                        <treeselect
                            :searchable="false"
                            :multiple="false"
                            :options="monthlyPricesTo"
                            :load-options="loadOptions"
                            placeholder="Per Month: Max"
                            :auto-load-root-options="false"
                            :defaultExpandLevel="1"
                            v-model="selectedMonthlyPricesTo"
                            v-on:select="setCurFilter('price')"
                            v-on:input="search()"
                        >
                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                        </treeselect>
                    </div>
                </div>
                <div v-show="!monthlyPriceSwitch" class="form-group">
                    <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="pricesFrom"
                        :load-options="loadOptions"
                        placeholder="Price: Min"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedPricesFrom"
                        v-on:select="setCurFilter('price')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                    <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="pricesTo"
                        :load-options="loadOptions"
                        placeholder="Price: Max"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedPricesTo"
                        v-on:select="setCurFilter('price')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                </div>
                <div class="form-group">
                    <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="yearsFrom"
                        :load-options="loadOptions"
                        placeholder="Year: Min"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedYearFrom"
                        v-on:select="setCurFilter('price')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                    <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="yearsTo"
                        :load-options="loadOptions"
                        placeholder="Year: Max"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedYearTo"
                        v-on:select="setCurFilter('price')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                </div>

                <treeselect
                    :searchable="false"
                    :multiple="true"
                    :options="locations"
                    :load-options="loadOptions"
                    placeholder="Any Location"
                    :auto-load-root-options="false"
                    :value-consists-of=this.$store.state.valueConsistsOf
                    :defaultExpandLevel="1"
                    v-model="selectedLocations"
                    v-on:select="setCurFilter('locations')"
                    v-on:input="search()"
                >
                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                </treeselect>
                <legend>Engine</legend>
                <div class="form-group">

                    <treeselect
                        :searchable="false"
                        v-show="mileageSwitch == 'mile'"
                        :multiple="false"
                        :options="mileagesFrom"
                        :load-options="loadOptions"
                        placeholder="Mileage: Min"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedMileageFrom"
                        v-on:select="setCurFilter('mileage')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                    <treeselect
                        :searchable="false"
                        v-show="mileageSwitch == 'mile'"
                        :multiple="false"
                        :options="mileagesTo"
                        :load-options="loadOptions"
                        placeholder="Mileage: Max"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedMileageTo"
                        v-on:select="setCurFilter('mileage')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>


                    <treeselect
                        :searchable="false"
                        v-show="mileageSwitch == 'kilometre'"
                        :multiple="false"
                        :options="kilometresFrom"
                        :load-options="loadOptions"
                        placeholder="Kilometres: Min"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedKilometresFrom"
                        v-on:select="setCurFilter('mileage')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                    <treeselect
                        :searchable="false"
                        v-show="mileageSwitch == 'kilometre'"
                        :multiple="false"
                        :options="kilometresTo"
                        :load-options="loadOptions"
                        placeholder="Kilometres: Max"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedKilometresTo"
                        v-on:select="setCurFilter('mileage')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                    <div class="form-radio-group mileage">
                        <div class="form-group-row">
                            <input v-model="mileageSwitch" value="mile" type="radio" name="units" id="miles" checked="checked" />
                            <label for="miles">mi</label>
                        </div>
                        <div class="form-group-row">
                            <input v-model="mileageSwitch" value="kilometre" type="radio" name="units" id="km"/>
                            <label for="km">km</label>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="engineSizesFrom"
                        :load-options="loadOptions"
                        placeholder="Engine: Min"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedEngineSizeFrom"
                        v-on:select="setCurFilter('engineSize')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                    <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="engineSizesTo"
                        :load-options="loadOptions"
                        placeholder="Engine: Max"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedEngineSizeTo"
                        v-on:select="setCurFilter('engineSize')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                </div>
                <div class="form-group">
                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="transmissionTypes"
                        :load-options="loadOptions"
                        placeholder="Gearbox"
                        :auto-load-root-options="false"
                        :defaultExpandLevel="1"
                        v-model="selectedTransmissionTypes"
                        v-on:select="setCurFilter('gearbox')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="fuelTypes"
                        :load-options="loadOptions"
                        placeholder="Fuel Type"
                        :auto-load-root-options="false"
                        v-model="selectedFuelTypes"
                        v-on:select="setCurFilter('fuelType')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                </div>

                <legend>Style</legend>
                <div class="form-group">

                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="bodyTypes"
                        :load-options="loadOptions"
                        placeholder="Body"
                        :auto-load-root-options="false"
                        v-model="selectedBodyType"
                        v-on:select="setCurFilter('bodyType')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>

                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="colours"
                        :load-options="loadOptions"
                        placeholder="Colour"
                        :auto-load-root-options="false"
                        v-model="selectedColours"
                        v-on:select="setCurFilter('colour')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>

                </div>
                <div class="form-group">

                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="seats"
                        :load-options="loadOptions"
                        placeholder="Seats"
                        :auto-load-root-options="false"
                        v-model="selectedSeats"
                        v-on:select="setCurFilter('seats')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>

                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="doors"
                        :load-options="loadOptions"
                        placeholder="Doors"
                        :auto-load-root-options="false"
                        v-model="selectedDoors"
                        v-on:select="setCurFilter('doors')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                </div>

                <legend>Other</legend>
                <div class="form-group">
                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="safetyRatings"
                        :load-options="loadOptions"
                        placeholder="Safety"
                        :auto-load-root-options="false"
                        v-model="selectedSafetyRatings"
                        v-on:select="setCurFilter('safetyRatings')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>

                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="fuelConsumption"
                        :load-options="loadOptions"
                        placeholder="Fuel Economy"
                        :auto-load-root-options="false"
                        v-model="selectedFuelConsumption"
                        v-on:select="setCurFilter('fuelConsumption')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                </div>
                <div class="form-group">

                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="environmental"
                        :load-options="loadOptions"
                        placeholder="Eco-Friendly"
                        :auto-load-root-options="false"
                        v-model="selectedEnvironmental"
                        v-on:select="setCurFilter('environmental')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>

                    <treeselect
                        :searchable="false"
                        :multiple="true"
                        :options="enginePowers"
                        :load-options="loadOptions"
                        placeholder="Engine Power"
                        :auto-load-root-options="false"
                        v-model="selectedEnginePower"
                        v-on:select="setCurFilter('enginePowers')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>

                </div>
                <div class="form-group">
                    <treeselect
                        :searchable="false"
                        style="width: 167px"
                        :multiple="true"
                        :options="accelerations"
                        :load-options="loadOptions"
                        placeholder="0-60"
                        :auto-load-root-options="false"
                        v-model="selectedAccelerations"
                        v-on:select="setCurFilter('accelerations')"
                        v-on:input="search()"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }} </div>
                    </treeselect>
                    <div class="refineform__emptycol"></div>
                </div>

                <legend>Refine your search by</legend>
                <div class="refineform__row refineme">
                    <div class="refineform__col">
                        <input type="text" name="refine" v-model="freeText" placeholder="eg. Sport, DCT, ABS" class="form-control" v-on:keyup.enter.stop="beginSearch()" />
                    </div>
                    <div class="form-radios">
                        <div>
                            <input type="radio" class="" name="freetextany" id="freetextall_mobile"  v-model="freeTextAny" value="false">
                            <label class="fulllabel" for="freetextall_mobile">Only ads with all these words</label>
                        </div>
                        <div>
                            <input type="radio" class=""  name="freetextany" id="freetextany_mobile" v-model="freeTextAny" value="true">
                            <label class="fulllabel" for="freetextany_mobile">Ads with any of these words</label>
                        </div>
                    </div>
                </div>


                <div class="form-group refineform__buttons">
                    <div class="form-group-column">
                        <button type="button" class="clear" v-on:click="resetSearch()">Clear Filters</button>
                    </div>
                    <div class="form-group-column">
                        <button type="button" data-id="closerefine"  v-on:click="beginSearch()">Begin Search</button>
                    </div>
                </div>
                <input  v-show=showNext id="myBtnNext" type="button" value="Next" class="button refineform__nextButton"/>
            </form>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapMutations} from "vuex"
    import { ToggleButton } from 'vue-js-toggle-button/dist/ssr.index'
    import Treeselect from '@riophae/vue-treeselect'
    import EventBus from '../../event-bus.js'
    import $ from 'jquery'

    export default {
        name: "RefineSearchMobile",
        components: {
            ToggleButton,
            Treeselect
        },
        data(){
            return {
                searchBlock: true,
                selected: null,
                options: ['Select option', 'options', 'selected', 'mulitple', 'label', 'searchable', 'clearOnSelect', 'hideSelected', 'maxHeight', 'allowEmpty', 'showLabels', 'onChange', 'touched'],
                showNext: false,
                freeText: null,
                freeTextAny: false
            }
        },
        computed: {
            disableModels() {
                return this.models == null;
            },
            selectedMakes: {
                get() {
                    return this.$store.state.searchMakeDesktop;
                },
                set(value) {
                    this.$store.commit('setSearchMakeDesktop', value);
                }
            },
            selectedModels: {
                get() {
                    return this.$store.state.searchModelDesktop;
                },
                set(value) {
                    this.$store.commit('setSearchModelDesktop', value);
                }
            },
            selectedLocations: {
                get() {
                    return this.$store.state.searchLocations;
                },
                set(value) {
                    this.$store.commit('setSearchLocations', value);
                }
            },
            selectedTransmissionTypes: {
                get() {
                    return this.$store.state.searchTransmissionType;
                },
                set(value) {
                    this.$store.commit('setSearchTransmissionType', value);
                }
            },
            selectedFuelTypes: {
                get() {
                    return this.$store.state.searchFuelType;
                },
                set(value) {
                    this.$store.commit('setSearchFuelType', value);
                }
            },
            selectedEngineSizeFrom: {
                get() {
                    return this.$store.state.searchEngineSizeFrom;
                },
                set(value) {
                    this.$store.commit('setSearchEngineSizeFrom', value);
                }
            },
            selectedEngineSizeTo: {
                get() {
                    return this.$store.state.searchEngineSizeTo;
                },
                set(value) {
                    this.$store.commit('setSearchEngineSizeTo', value);
                }
            },
            selectedMonthlyPricesFrom: {
                get() {
                    return this.$store.state.searchMonthlyPricesFrom;
                },
                set(value) {
                    this.$store.commit('setSearchMonthlyPricesFrom', value);
                }
            },
            selectedMonthlyPricesTo: {
                get() {
                    return this.$store.state.searchMonthlyPricesTo;
                },
                set(value) {
                    this.$store.commit('setSearchMonthlyPricesTo', value);
                }
            },
            selectedFinanceDeposit: {
                get() {
                    return this.$store.state.searchFinanceDeposit;
                },
                set(value) {
                    this.$store.commit('setSearchFinanceDeposit', value);
                }
            },
            selectedYearFrom: {
                get() {
                    return this.$store.state.searchYearFrom;
                },
                set(value) {
                    this.$store.commit('setSearchYearFrom', value);
                }
            },
            selectedYearTo: {
                get() {
                    return this.$store.state.searchYearTo;
                },
                set(value) {
                    this.$store.commit('setSearchYearTo', value);
                }
            },
            selectedMileageFrom: {
                get() {
                    return this.$store.state.searchMileageFrom;
                },
                set(value) {
                    this.$store.commit('setSearchMileageFrom', value);
                }
            },
            selectedMileageTo: {
                get() {
                    return this.$store.state.searchMileageTo;
                },
                set(value) {
                    this.$store.commit('setSearchMileageTo', value);
                }
            },
            selectedKilometresFrom: {
                get() {
                    return this.$store.state.searchKilometresFrom;
                },
                set(value) {
                    this.$store.commit('setSearchKilometresFrom', value);
                }
            },
            selectedKilometresTo: {
                get() {
                    return this.$store.state.searchKilometresTo;
                },
                set(value) {
                    this.$store.commit('setSearchKilometresTo', value);
                }
            },
            selectedPricesFrom: {
                get() {
                    return this.$store.state.searchPriceFrom;
                },
                set(value) {
                    this.$store.commit('setSearchPriceFrom', value);
                }
            },
            selectedPricesTo: {
                get() {
                    return this.$store.state.searchPriceTo;
                },
                set(value) {
                    this.$store.commit('setSearchPriceTo', value);
                }
            },

            monthlyPriceSwitch: {
                get () {
                    return this.$store.state.monthlyPriceSwitch;
                },
                set (value) {
                    this.resetPrice(value);
                    this.$store.commit('setMonthlyPriceSwitch', value);
                }
            },
            mileageSwitch: {
                get () {
                    return this.$store.state.mileageSwitch;
                },
                set (value) {
                    this.resetMileage(value);
                    this.$store.commit('setMileageSwitch', value);
                }
            },
            selectedBodyType: {
                get() {
                    return this.$store.state.searchBodyType;
                },
                set(value) {
                    this.$store.commit('setSearchBodyType', value);
                }
            },
            selectedColours: {
                get() {
                    return this.$store.state.searchColour;
                },
                set(value) {
                    this.$store.commit('setSearchColour', value);
                }
            },
            selectedSeats: {
                get() {
                    return this.$store.state.searchSeats;
                },
                set(value) {
                    this.$store.commit('setSearchSeats', value);
                }
            },
            selectedDoors: {
                get() {
                    return this.$store.state.searchDoors;
                },
                set(value) {
                    this.$store.commit('setSearchDoors', value);
                }
            },
            selectedSafetyRatings: {
                get() {
                    return this.$store.state.searchSafetyRating;
                },
                set(value) {
                    this.$store.commit('setSearchSafetyRating', value);
                }
            },
            selectedFuelConsumption: {
                get() {
                    return this.$store.state.searchFuelConsumption;
                },
                set(value) {
                    this.$store.commit('setSearchFuelConsumption', value);
                }
            },
            selectedEnvironmental: {
                get() {
                    return this.$store.state.searchEnvironmental;
                },
                set(value) {
                    this.$store.commit('setSearchEnvironmental', value);
                }
            },
            selectedEnginePower: {
                get() {
                    return this.$store.state.searchEnginePower;
                },
                set(value) {
                    this.$store.commit('setSearchEnginePower', value);
                }
            },
            selectedAccelerations: {
                get() {
                    return this.$store.state.searchAcceleration;
                },
                set(value) {
                    this.$store.commit('setSearchAcceleration', value);
                }
            },
            ...mapState([
                'openMobileRefine',

                'makes',
                'models',
                'makeModels',
                'years',
                'yearsFrom',
                'yearsTo',

                'prices',
                'pricesFrom',
                'pricesTo',
                'financeDeposits',
                'deposits',
                'monthlyPrices',
                'monthlyPricesFrom',
                'monthlyPricesTo',
                'locations',
                'mileageKilometers',
                'mileages',
                'mileagesFrom',
                'mileagesTo',
                'kilometres',
                'kilometresFrom',
                'kilometresTo',
                'engineSizes',
                'engineSizesFrom',
                'engineSizesTo',
                'transmissionTypes',
                'fuelTypes',
                'bodyTypes',
                'colours',
                'seats',
                'doors',
                'safetyRatings',
                'fuelConsumption',
                'environmental',
                'enginePowers',
                'accelerations',

                'searchMake',
                'searchMakeDesktop',
                'searchModel',
                'searchYearFrom',
                'searchYearTo',
                'searchPriceFrom',
                'searchPriceTo',
                'searchFinanceDeposit',
                'searchMonthlyPricesFrom',
                'searchMonthlyPricesTo',
                'searchLocations',
                'searchMileageFrom',
                'searchMileageTo',
                'searchKilometresFrom',
                'searchKilometresTo',
                'searchEngineSizeFrom',
                'searchEngineSizeTo',
                'searchTransmissionType',
                'searchFuelType',
                'searchBodyType',
                'searchColour',
                'searchSeats',
                'searchDoors',
                'searchSafetyRating',
                'searchFuelConsumption',
                'searchEnvironmental',
                'searchEnginePower',
                'searchAcceleration',

                'resultsLoaded',
                'searchResults',
            ]),


        },
        mounted(){

            this.freeText = this.$store.state.freeText;
            this.freeTextAny = this.$store.state.freeTextAny != null ? this.$store.state.freeTextAny : false;

            $(function () {
                "use strict";

                $("[data-class=openrefine]").click((e) => {
                    e.preventDefault();
                    $("[data-id=refineform]").show();
                    $("body").addClass("static");
                    $('header').hide();
                });

                $("[data-id=closerefine]").click((e) => {
                    e.preventDefault();
                    $("[data-id=refineform]").hide();
                    $("body").removeClass("static")
                    $('header').show();
                });

                $(".clear").click((e) => {
                    e.preventDefault();
                    $("[data-id=refineform]").hide();
                    $("body").removeClass("static");
                    $('header').show();
                });

            });

            //Prevent the search to redirect on friendly urls
            this.preventFriendlyUrlRedirect();
        },

        methods: {
            ...mapMutations([
                'resetOpenMobileRefine',
                'setSearchMake',
                'setSearchModel',
                'setSearchYearFrom',
                'setSearchYearTo',
                'setSearchPriceFrom',
                'setSearchPriceTo',
                'setSearchLocations',
                'setSearchMileageFrom',
                'setSearchMileageTo',
                'setSearchEngineSizeFrom',
                'setSearchEngineSizeTo',
                'setSearchTransmissionType',
                'setSearchFuelType',
                'setSearchBodyType',
                'setSearchColour',
                'setSearchSeats',
                'setSearchDoors',
                'setSearchSafetyRating',
                'setSearchFuelConsumption',
                'setSearchEnvironmental',
                'setSearchEnginePower',
                'setSearchAcceleration',
                'setCurrentFilter',
                'setUrlParams',
                'setUrl',
                'setInternalSource'
            ]),
            ...mapActions([
                'loadMakesAction',
                'loadModelsAction',
                'loadYearsAction',
                'syncLoadYears',
                'loadFiltersAction',
                'syncLoadFiltersAction',
                'updateUrlAction',
                'setSearchMakeAction',
                'setSearchModelAction',
            ]),
            async loadOptions() {
            },
            preventFriendlyUrlRedirect()
            {
                let that = this;
                setTimeout(function(){
                    that.searchBlock = false;
                }, 1000);
            },
            beginSearch(){
                //Check if the free text search is set
                if(this.freeText != null){
                    this.$store.commit('setFreeText', this.freeText);
                    this.$store.commit('setFreeTextAny', this.freeTextAny);
                    this.search();
                }
                $("[data-id=refineform]").hide();
                $("body").removeClass("static");
                $('header').show();
            },
            search() {
                this.$store.commit('setRequestedPage', 1);
                if(this.getCookie('g') == '1'){
                    this.$store.commit('setDealerGroup', 1);
                }
                if(this.searchBlock){
                    return false;
                }else{
                    //Internal search
                    this.updateUrlAction();
                    this.updateVueRouter();
                }
            },
            getCookie (name) {
                let value = `; ${document.cookie}`;
                let parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            },
            resetPrice(value){
                if(this.$store.state.justLoaded == true) {
                    if (value == true) {
                        this.$store.commit('setSearchPriceFrom', null);
                        this.$store.commit('setSearchPriceTo', null);
                    } else {
                        this.$store.commit('setSearchMonthlyPricesFrom', null);
                        this.$store.commit('setSearchMonthlyPricesTo', null);
                        this.$store.commit('setSearchFinanceDeposit', null);
                    }
                    EventBus.$emit('search');
                }
            },
            resetMileage(value){
                if(this.$store.state.justLoaded == true) {
                    if (value == 'mile') {
                        this.$store.commit('setSearchKilometresTo', null);
                        this.$store.commit('setSearchKilometresFrom', null);
                    } else {
                        this.$store.commit('setSearchMileageFrom', null);
                        this.$store.commit('setSearchMileageTo', null);
                    }
                    EventBus.$emit('search');
                }
            },
            resetSearch(){
                this.$store.commit('setSearchMakeDesktop', []);
                this.$store.commit('setSearchModelDesktop', []);
                this.$store.commit('setSearchLocations', []);
                this.$store.commit('setSearchMonthlyPricesFrom', null);
                this.$store.commit('setSearchMonthlyPricesTo', null);
                this.$store.commit('setSearchFinanceDeposit', null);
                this.$store.commit('setMonthlyPriceSwitch', false);
                this.$store.commit('setSearchPriceFrom', null);
                this.$store.commit('setSearchPriceTo', null);
                this.$store.commit('setSearchYearFrom', null);
                this.$store.commit('setSearchYearTo', null);
                this.$store.commit('setSearchMileageFrom', null);
                this.$store.commit('setSearchMileageTo', null);
                this.$store.commit('setSearchEngineSizeFrom', null);
                this.$store.commit('setSearchEngineSizeTo', null);
                this.$store.commit('setSearchTransmissionType', []);
                this.$store.commit('setSearchFuelType', []);
                this.$store.commit('setSearchBodyType', []);
                this.$store.commit('setSearchColour', []);
                this.$store.commit('setSearchSeats', []);
                this.$store.commit('setSearchDoors', []);
                this.$store.commit('setSearchSafetyRating', []);
                this.$store.commit('setSearchFuelConsumption', []);
                this.$store.commit('setSearchEnvironmental', []);
                this.$store.commit('setSearchEnginePower', []);
                this.$store.commit('setSearchAcceleration', []);
                this.$store.commit('setFreeText', null);
                this.$store.commit('setFreeTextAny', false);
                this.freeTextAny=false;
                this.freeText=null;
                this.search();
            },
            setCurFilter(filter){
                this.setCurrentFilter(filter);
                this.setInternalSource('1');
                EventBus.$emit('clearPagination');
            },
            resetFilter(methodName){
                this.$store.commit(methodName, []);
            },
            updateVueRouter() {
                let newQueryString = '/' + this.$router.currentRoute.name + '/' + Object.keys(this.$store.state.urlParams).map(key => key + '-' + this.$store.state.urlParams[key]).join('/');
                let currentQueryString = this.$router.currentRoute.fullPath;
                if (newQueryString != currentQueryString) {
                    this.$router.push({path: newQueryString.toLowerCase()});
                }
            },
            blockAction(myElement, action){
                if(action == 'open'){

                    let that = this;
                    this.showNext = true;
                    this.$store.state.quickSearchOverlay = true;

                    setTimeout(function(){
                        if(!document.getElementsByClassName('vue-treeselect__list')[0] ){
                            that.showNext = false;
                        }

                        if(typeof document.getElementsByClassName('vue-treeselect__menu')[0] === 'undefined'){
                            that.$store.state.quickSearchOverlay = false;
                            return false;
                        }

                         that.setNextButtonDimensions();

                        let makeControleHeight = 0;
                        let modelControleHeight = 0;
                        let offset = 0;
                        let difference = 0;

                        if(myElement == 'make'){
                            let res = document.getElementsByClassName('refineform__col')[0];
                            let paddingTop = window.getComputedStyle(res, null).getPropertyValue('height');
                            makeControleHeight = parseInt(paddingTop.replace('px', ''));
                            offset = document.getElementsByClassName('vue-treeselect__menu')[0].clientHeight;
                            difference = 81;
                            // Clean up models
                            that.$store.commit('setSearchModelDesktop', []);
                        }else{
                            makeControleHeight = document.getElementsByClassName('refineform__col')[0].clientHeight;
                            modelControleHeight = document.getElementsByClassName('refineform__col')[1].clientHeight;
                            offset = document.getElementsByClassName('vue-treeselect__menu')[0].offsetHeight;
                            offset += modelControleHeight;
                            difference = 86;
                        }
                        document.getElementById("myBtnNext").style.top = offset + difference + makeControleHeight + "px";

                    }, 10);
                }else{
                    this.showNext = false;
                    this.$store.state.quickSearchOverlay = false;
                }

            },
            setNextButtonDimensions(){
                //Get the dimension of the Begin Search Button
                let buttonWidth = document.getElementsByClassName("refineform__col--full")[0].offsetWidth + "px";
                document.getElementById("myBtnNext").style.width = buttonWidth;
                let res = document.getElementsByClassName('refineform--open')[0];
                if(typeof res === 'undefined'){
                    this.$store.state.quickSearchOverlay = false;
                    return false;
                }else{
                    this.showNext = true;
                }
                let paddingRight = window.getComputedStyle(res, null).getPropertyValue('padding-right');
                document.getElementById("myBtnNext").style.left = paddingRight;
            }
        }
    }
</script>

<style scoped>

</style>
