    <template>
        <div class="refinesearch">
            <div class="refinesearch__mobile">
                <div class="refinesearch__mobile_left">
                    <span href="javascript:void(0)" data-class="openrefine">Refine</span>
                </div>
                <div class="refinesearch__mobile_right">
                    <select name="sort_mobile_by"
                            class="form-control"
                            @change="orderByChange"
                            :value="searchOrderBy"
                    >
                        <option v-for="(item, index) in arrSortBy" :value="item.value" v-bind:key="index">{{ item.text }}</option>
                    </select>
                </div>
            </div>
            <div class="vld-parent">
                <loading :active.sync="isLoading"
                         :can-cancel="false"
                         :is-full-page="fullPage">
                </loading>
            </div>

            <div class="refinesearch__desktop">
                <div class="reset-search">
                    <SelectedFilters />
                </div>
                <div class="refinesearch__menu">
                    <ul>
                        <li><div data-target="refine__make" class="widget">Make</div><span>{{ formattedMakesLabel }}</span></li>
                        <li><div data-target="refine__model" :class="modelClass" class="widget">Model</div><span>{{ formattedModelsLabel }}</span></li>
                        <li><div data-target="refine__year" class="reveal">Year</div><span class="any">{{ formattedYearsLabel }}</span>
                            <div class="refine__reveal" data-id="refine__year">
                                <div class="refine__year">
                                    <div class="form-group-row form-group-row--flex">
                                        <label for="year_from">From</label>
                                        <treeselect
                                            :searchable="false"
                                            id="year_from"
                                            class="form-control"
                                            :multiple="false"
                                            :options="yearsFrom"
                                            :load-options="loadOptions"
                                            :auto-load-root-options="false"
                                            placeholder="(any)"
                                            v-on:select="setCurFilter('years')"
                                            :value="this.$store.state.searchYearFrom"
                                            v-on:input="search($event, 'searchYearFrom')"
                                        >
                                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                        </treeselect>
                                    </div>
                                    <div class="form-group-row form-group-row--flex">
                                        <label for="year_to">To</label>
                                        <treeselect
                                            :searchable="false"
                                            id="year_to"
                                            class="form-control"
                                            :multiple="false"
                                            :options="yearsTo"
                                            :load-options="loadOptions"
                                            :auto-load-root-options="false"
                                            placeholder="(any)"
                                            v-on:select="setCurFilter('years')"
                                            :value="this.$store.state.searchYearTo"
                                            v-on:input="search($event, 'searchYearTo')"
                                        >
                                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                        </treeselect>

                                    </div>
                                </div>
                                <button class="close btn-clear-filter"
                                        v-if="this.$store.state.searchYearFrom || this.$store.state.searchYearTo"
                                        @click="resetFilter('year')">Clear filters
                                </button>
                            </div>
                        </li>
                        <li><div data-target="refine__price" class="reveal">Price</div><span class="any">{{ formattedPriceLabel }}</span>
                            <div class="refine__reveal" data-id="refine__price">
                                <div class="refine__price">
                                    <div class="form-group-row form-group-row--center">

                                        <div class="refine__togglelabels">
                                            <label>Cash<br/> Price</label>
                                            <label>Monthly<br/>Price</label>
                                        </div>
                                        <label class="toggleswitch toggleswitch--light">
                                            <toggle-button id="monthlypricerefine" v-model="monthlyPriceSwitch" :margin=0></toggle-button>
                                        </label>
                                    </div>
                                    <div class="refine__price" v-show="monthlyPriceSwitch">
                                        <label for="deposit">Deposit / Trade-In Amount</label>
                                        <treeselect
                                            :searchable="false"
                                            id="deposit"
                                            class="form-control deposit"
                                            :multiple="false"
                                            :options="financeDeposits"
                                            :load-options="loadOptions"
                                            :auto-load-root-options="false"
                                            placeholder="(any)"
                                            v-on:select="setCurFilter('price')"
                                            :value="this.$store.state.searchFinanceDeposit"
                                            v-on:input="search($event, 'searchFinanceDeposits')"
                                        >
                                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                        </treeselect>
                                        <div class="form-group-row">
                                            <label>Monthly Price</label>
                                        </div>
                                        <div class="form-group-row form-group-row--flex">
                                            <label for="monthly_price_from">From</label>
                                            <treeselect
                                                :searchable="false"
                                                id="monthly_price_from"
                                                class="form-control"
                                                :multiple="false"
                                                :options="monthlyPricesFrom"
                                                :load-options="loadOptions"
                                                :auto-load-root-options="false"
                                                placeholder="(any)"
                                                v-on:select="setCurFilter('prices')"
                                                :value="this.$store.state.searchMonthlyPricesFrom"
                                                v-on:input="search($event, 'monthlyPricesFrom')"
                                            >
                                                <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                            </treeselect>
                                        </div>
                                        <div class="form-group-row form-group-row--flex">
                                            <label for="monthly_price_to">To</label>
                                            <treeselect
                                                :searchable="false"
                                                id="monthly_price_to"
                                                class="form-control"
                                                :multiple="false"
                                                :options="monthlyPricesTo"
                                                :load-options="loadOptions"
                                                :auto-load-root-options="false"
                                                placeholder="(any)"
                                                v-on:select="setCurFilter('prices')"
                                                :value="this.$store.state.searchMonthlyPricesTo"
                                                v-on:input="search($event, 'monthlyPricesTo')"
                                            >
                                                <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                            </treeselect>
                                        </div>
                                    </div>
                                    <div v-show="!monthlyPriceSwitch">
                                        <div class="form-group-row form-group-row--flex">
                                            <label for="price_from">From</label>
                                            <treeselect
                                                :searchable="false"
                                                id="price_from"
                                                class="form-control"
                                                :multiple="false"
                                                :options="pricesFrom"
                                                :load-options="loadOptions"
                                                :auto-load-root-options="false"
                                                placeholder="(any)"
                                                v-on:select="setCurFilter('prices')"
                                                :value="this.$store.state.searchPriceFrom"
                                                v-on:input="search($event, 'searchPriceFrom')"
                                            >
                                                <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                            </treeselect>
                                        </div>
                                        <div class="form-group-row form-group-row--flex">
                                            <label for="price_to">To</label>
                                            <treeselect
                                                :searchable="false"
                                                id="price_to"
                                                class="form-control"
                                                :multiple="false"
                                                :options="pricesTo"
                                                :load-options="loadOptions"
                                                :auto-load-root-options="false"
                                                placeholder="(any)"
                                                v-on:select="setCurFilter('prices')"
                                                :value="this.$store.state.searchPriceTo"
                                                v-on:input="search($event, 'searchPriceTo')"
                                            >
                                                <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                            </treeselect>
                                        </div>
                                    </div>
                                </div>
                                <button class="close btn-clear-filter"
                                        v-if="this.$store.state.searchPriceFrom ||
                                        this.$store.state.searchPriceTo ||
                                        this.$store.state.searchMonthlyPricesFrom ||
                                        this.$store.state.searchMonthlyPricesTo ||
                                        this.$store.state.searchFinanceDeposit"
                                        @click="resetFilter('price')">Clear filters
                                </button>
                            </div>
                        </li>
                        <li><div data-target="refine__location" class="widget">Location</div><span>{{formattedLocationLabel}}</span></li>
                        <li><div data-target="refine__mileage" class="reveal">Mileage</div><span class="any">{{ formattedMileageKmLabel }}</span>
                            <div class="refine__reveal" data-id="refine__mileage">
                                <div class="refine__mileage">
                                    <div class="refine__mileage_units">
                                        <label>Miles</label>
                                        <input type="radio" class="custom-radio" v-model="mileageSwitch" name="units" value="mile" id="milesRefine" checked="checked" />
                                        <label for="milesRefine" class="custom-radio-label"></label>
                                        <label for="kmRefine">Kilometres</label>
                                        <input type="radio" class="custom-radio" v-model="mileageSwitch" name="units" value="kilometre" id="kmRefine" />
                                        <label for="kmRefine" class="custom-radio-label km"></label>
                                    </div>
                                    <div v-show="mileageSwitch == 'mile'">
                                        <div class="form-group-row form-group-row--flex">
                                            <label for="mileage_from">From</label>
                                            <treeselect
                                                :searchable="false"
                                                id="mileage_from"
                                                class="form-control"
                                                :multiple="false"
                                                :options="mileagesFrom"
                                                placeholder="(any)"
                                                :load-options="loadOptions"
                                                :auto-load-root-options="false"
                                                v-on:select="setCurFilter('mileage')"
                                                :value="this.$store.state.searchMileageFrom"
                                                v-on:input="search($event, 'searchMileageFrom')"
                                            >
                                                <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                            </treeselect>

                                        </div>
                                        <div class="form-group-row form-group-row--flex">
                                            <label for="mileage_to">To</label>
                                            <treeselect
                                                :searchable="false"
                                                id="mileage_to"
                                                class="form-control"
                                                :multiple="false"
                                                :options="mileagesTo"
                                                :load-options="loadOptions"
                                                :auto-load-root-options="false"
                                                placeholder="(any)"
                                                v-on:select="setCurFilter('mileage')"
                                                :value="this.$store.state.searchMileageTo"
                                                v-on:input="search($event, 'searchMileageTo')"
                                            >
                                                <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                            </treeselect>
                                        </div>
                                    </div>
                                    <div v-show="mileageSwitch == 'kilometre'">
                                        <div class="form-group-row form-group-row--flex">
                                            <label for="kilometer_from">From</label>
                                            <treeselect
                                                :searchable="false"
                                                id="kilometer_from"
                                                class="form-control"
                                                :multiple="false"
                                                :options="kilometresFrom"
                                                :load-options="loadOptions"
                                                :auto-load-root-options="false"
                                                placeholder="(any)"
                                                v-on:select="setCurFilter('mileage')"
                                                :value="this.$store.state.searchKilometresFrom"
                                                v-on:input="search($event, 'searchKilometresFrom')"
                                            >
                                                <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                            </treeselect>

                                        </div>
                                        <div class="form-group-row form-group-row--flex">
                                            <label for="kilometer_to">To</label>
                                            <treeselect
                                                :searchable="false"
                                                id="kilometer_to"
                                                class="form-control"
                                                :multiple="false"
                                                :options="kilometresTo"
                                                :load-options="loadOptions"
                                                :auto-load-root-options="false"
                                                placeholder="(any)"
                                                v-on:select="setCurFilter('mileage')"
                                                :value="this.$store.state.searchKilometresTo"
                                                v-on:input="search($event, 'searchKilometresTo')"
                                            >
                                                <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                            </treeselect>
                                        </div>
                                    </div>
                                </div>
                                <button class="close btn-clear-filter"
                                        v-if="this.$store.state.searchMileageFrom ||
                                        this.$store.state.searchMileageTo ||
                                        this.$store.state.searchKilometresFrom ||
                                        this.$store.state.searchKilometresTo"
                                        @click="resetFilter('mileage')">Clear filters
                                </button>
                            </div>
                        </li>
                        <li><div data-target="refine__enginesize" class="reveal">Engine Size</div><span class="any">{{ formattedEngineSizeLabel }}</span>
                            <div class="refine__reveal" data-id="refine__enginesize">
                                <div class="refine__enginesize">
                                    <div class="form-group-row form-group-row--flex">
                                        <label for="enginesize_from">From</label>
                                        <treeselect
                                            :searchable="false"
                                            id="enginesize_from"
                                            class="form-control"
                                            :multiple="false"
                                            :options="engineSizesFrom"
                                            :load-options="loadOptions"
                                            :auto-load-root-options="false"
                                            placeholder="(any)"
                                            v-on:select="setCurFilter('engineSize')"
                                            :value="this.$store.state.searchEngineSizeFrom"
                                            v-on:input="search($event, 'searchEngineSizeFrom')"
                                        >
                                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                        </treeselect>

                                    </div>
                                    <div class="form-group-row form-group-row--flex">
                                        <label for="enginesize_to">To</label>
                                        <treeselect
                                            :searchable="false"
                                            id="enginesize_to"
                                            class="form-control"
                                            :multiple="false"
                                            :options="engineSizesTo"
                                            :load-options="loadOptions"
                                            :auto-load-root-options="false"
                                            placeholder="(any)"
                                            v-on:select="setCurFilter('engineSize')"
                                            :value="this.$store.state.searchEngineSizeTo"
                                            v-on:input="search($event, 'searchEngineSizeTo')"
                                        >
                                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                                        </treeselect>

                                    </div>
                                </div>
                                <button class="close btn-clear-filter"
                                        v-if="this.$store.state.searchEngineSizeFrom || this.$store.state.searchEngineSizeTo"
                                        @click="resetFilter('engine-size')">Clear filters
                                </button>

                            </div>
                        </li>
                        <li><div data-target="refine__gearbox" class="widget">Gearbox</div><span>{{ formattedTransmissionTypeLabel }}</span></li>
                        <li><div data-target="refine__fueltype" class="widget">Fuel Type</div><span>{{ formattedFuelTypeLabel }}</span></li>
                        <li><div data-target="refine__bodytype" class="widget">Body Type</div><span>{{ formattedBodyTypeLabel }}</span></li>
                        <li><div data-target="refine__colour" class="widget">Colour</div><span>{{ formattedColourLabel }}</span></li>
                        <li><div data-target="refine__seats" class="widget">Seats</div><span>{{ formattedSeatsLabel }}</span></li>
                        <li><div data-target="refine__doors" class="widget">Doors</div><span>{{ formattedDoorsLabel }}</span></li>
                        <li><div data-target="refine__safety" class="widget">Safety Rating</div><span>{{ formattedSafetyRatingsLabel }}</span></li>
                        <li><div data-target="refine__fuelconsumption" class="widget">Fuel Consumption</div><span>{{ formattedFuelConsumptionLabel }}</span></li>
                        <li><div data-target="refine__environmental" class="widget">Environmental</div><span>{{ formattedEnvironmentalLabel }}</span></li>
                        <li><div data-target="refine__enginepower" class="widget">Engine Power</div><span>{{ formattedEnginePowerLabel }}</span></li>
                        <li><div data-target="refine__acceleration" class="widget">Acceleration</div><span>{{ formattedAccelerationLabel }}</span></li>
                        <li>
                            <div class="refine__freetext">
                                    <legend>Refine your search by</legend>
                                    <div class="form form-inline">
                                        <input type="text" name="refine" id="refine" v-model="freeText" placeholder="eg. Sport, DCT, ABS" class="form-control input-sm" v-on:keyup.enter="freeTextSearch" />
                                        <button v-on:click="freeTextSearch" class="btn btn-primary">Go</button>
                                    </div>
                                    <div class="form-radios">
                                        <div>
                                            <input type="radio" class="custom-radio special-radio" name="freetextany" id="freetextall"  v-model="freeTextAny" value="false">
                                            <label for="freetextall" class="custom-radio-label"></label>
                                            <label class="fulllabel" for="freetextall">Only ads with all these words</label>
                                        </div>
                                        <div>
                                            <input type="radio" class="custom-radio special-radio"  name="freetextany" id="freetextany" v-model="freeTextAny" value="true">
                                            <label for="freetextany" class="special-label custom-radio-label"></label>
                                            <label class="fulllabel" for="freetextany">Ads with any of these words</label>
                                        </div>
                                    </div>
                            </div>

                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </template>

    <script>

        import Treeselect from '@riophae/vue-treeselect'
        import Loading from 'vue-loading-overlay'
        import { ToggleButton } from 'vue-js-toggle-button/dist/ssr.index'
        import {mapState, mapMutations, mapActions} from 'vuex';
        import $ from 'jquery'
        import SelectedFilters from "@/components/partials/SelectedFiilters.vue";

        export default {
            name: "RefineSearch",
            components: {
                SelectedFilters,
                Loading,
                ToggleButton,
                Treeselect
            },
            data: function (){
                return {
                    searchBlock: true,
                    fullPage: true,
                    checked: false,
                    freeTextAny: false,
                    arrSortBy : [
                        { 'value' : 'price-desc', 'text':'Price: Highest First'},
                        { 'value' : 'price-asc', 'text':'Price: Lowest First'},
                        { 'value' : 'finance' , 'text': 'Finance Option Available' },
                        { 'value' : 'make-asc' , 'text': 'Make: A to Z' },
                        { 'value' : 'year-desc' , 'text': 'Year: Newest First' },
                        { 'value' : 'year-asc' , 'text': 'Year: Oldest First' },
                        { 'value' : 'mileage-asc' , 'text': 'Mileage: Lowest First' },
                        { 'value' : 'enginesize-desc' , 'text': 'Engine Size: Largest First' },
                        { 'value' : 'enginesize-asc' , 'text': 'Engine Size: Smallest First' },
                        { 'value' : 'fuelconsumption' , 'text': 'Fuel Economy: Best First' },
                        { 'value' : 'safetyrating' , 'text': 'Safety Rating: Best First' },
                        { 'value' : 'acceleration' , 'text': 'Acceleration: Fastest First' },
                        { 'value' : 'pullingpower' , 'text': 'Pulling Power: Strongest First' },
                        { 'value' : 'location', 'text': 'Location: A to Z'},
                        { 'value' : 'recent' , 'text': 'Most Recently Added'}
                    ]
                }
            },
            computed: {
                freeText: {
                    get () {
                        return this.$store.state.freeText;
                    },
                    set (value) {
                        this.$store.commit('setFreeText', value);
                    }
                },
                searchOrderBy:{
                    get() {
                        let res = this.$store.state.searchOrderBy;
                        let params = this.$store.state.urlParams;
                        delete params.s;
                        delete params.sortby;
                        if(res == 'price-desc' && this.$store.state.manualSortBy == false && Object.keys(params).length > 0){
                            res = 'price-asc';
                        }
                        return res;
                    },
                    set(value) {
                        this.$store.commit('setSearchOrderBy', value);
                    }
                },
                monthlyPriceSwitch: {
                    get () {
                        return this.$store.state.monthlyPriceSwitch;
                    },
                    set (value) {
                        this.$store.commit('setMonthlyPriceSwitch', value);
                        this.clearPagination();
                        this.resetPrice(value);
                    }
                },
                mileageSwitch: {
                    get () {
                        return this.$store.state.mileageSwitch;
                    },
                    set (value) {
                        this.$store.commit('setMileageSwitch', value);
                        this.resetMileage(value);
                    }
                },
                modelClass(){
                    return this.$store.state.searchMakeDesktop.length > 0 ? '' : ' disabled';
                },
                disableModels() {
                    return this.models == null;
                },
                formattedMakesLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchMakeDesktop.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchMakeDesktop.length == 1){
                        if(this.makes != null) {
                            res = this.makes.filter(county => county.id == this.$store.state.searchMakeDesktop[0]);
                            if(res.length > 0){
                                res = res[0].customLabel
                            }
                        }
                    }
                    return res;
                },
                formattedModelsLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchModelDesktop.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchModelDesktop.length == 1){
                        if(this.$store.state.makeModels != null) {
                            res = this.$store.state.makeModels.filter(model => model.id == this.$store.state.searchModelDesktop[0]);
                            if(res.length > 0){
                                let myModel = typeof res[0].id === 'string' ? res[0].id.replace(/\+/g, ' ') : res[0].id;
                                const words = myModel.split(" ");
                                for (let i = 0; i < words.length; i++) {
                                    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                                }
                                res = words.join(" ");
                            }
                        }
                    }
                    return res;
                },
                formattedYearsLabel() {
                    let res = 'Any';
                    if(this.yearsFrom && this.$store.state.searchYearFrom != null && (this.$store.state.searchYearFrom == this.$store.state.searchYearTo )){
                        res = this.yearsFrom.filter(year => year.id == this.$store.state.searchYearFrom);
                        res = (res != null && res.length == 1) ? res[0].label : '';
                    }else if(this.$store.state.searchYearFrom != null && this.$store.state.searchYearTo != null){
                        res = 'Multiple';
                    }else if(this.$store.state.searchYearFrom != null && this.$store.state.searchYearTo == null) {
                        if(this.yearsFrom != null){
                            res = this.yearsFrom.filter(year => year.id == this.$store.state.searchYearFrom);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }else if(this.$store.state.searchYearFrom == null && this.$store.state.searchYearTo != null) {
                        if(this.yearsTo != null){
                            res = this.yearsTo.filter(year => year.id == this.$store.state.searchYearTo);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }
                    return res;
                },
                formattedEngineSizeLabel() {
                    let res = 'Any';
                    if(this.$store.state.searchEngineSizeFrom != null && this.$store.state.searchEngineSizeTo != null){
                        res = 'Multiple';
                    }else if(this.$store.state.searchEngineSizeFrom != null && this.$store.state.searchEngineSizeTo == null) {
                        if(this.engineSizesFrom != null){
                            res = this.engineSizesFrom.filter(engineSize => engineSize.id == this.$store.state.searchEngineSizeFrom);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }else if(this.$store.state.searchEngineSizeFrom == null && this.$store.state.searchEngineSizeTo != null) {
                        if(this.engineSizesTo != null){
                            res = this.engineSizesTo.filter(engineSize => engineSize.id == this.$store.state.searchEngineSizeTo);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }
                    return res;
                },
                formattedMileageKmLabel() {
                    let res = 'Any';
                    if(this.$store.state.searchMileageFrom != null && this.$store.state.searchMileageTo != null){
                        res = 'Multiple';
                    }else if(this.$store.state.searchMileageFrom != null && this.$store.state.searchMileageTo == null) {
                        if(this.mileagesFrom != null){
                            res = this.mileagesFrom.filter(val => val.id == this.$store.state.searchMileageFrom);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }else if(this.$store.state.searchMileageFrom == null && this.$store.state.searchMileageTo != null) {
                        if(this.mileagesTo != null){
                            res = this.mileagesTo.filter(val => val.id == this.$store.state.searchMileageTo);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }else if(this.$store.state.searchKilometresFrom != null && this.$store.state.searchKilometresTo != null){
                        res = 'Multiple';
                    }else if(this.$store.state.searchKilometresFrom != null && this.$store.state.searchKilometresTo == null){
                        if(this.kilometresFrom != null){
                            res = this.kilometresFrom.filter(val => val.id == this.$store.state.searchKilometresFrom);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }else if(this.$store.state.searchKilometresFrom == null && this.$store.state.searchKilometresTo != null){
                        if(this.kilometresTo != null){
                            res = this.kilometresTo.filter(val => val.id == this.$store.state.searchKilometresTo);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }
                    return res;
                },
                formattedPriceLabel() {
                    let res = 'Any';
                    if(this.$store.state.searchPriceFrom != null && this.$store.state.searchPriceTo != null){
                        res = 'Multiple';
                    }else if(this.$store.state.searchPriceFrom != null && this.$store.state.searchPriceTo == null) {
                        if(this.pricesFrom != null){
                            res = this.pricesFrom.filter(price => price.id == this.$store.state.searchPriceFrom);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }else if(this.$store.state.searchPriceFrom == null && this.$store.state.searchPriceTo != null) {
                        if(this.pricesTo != null){
                            res = this.pricesTo.filter(price => price.id == this.$store.state.searchPriceTo);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }else if(this.$store.state.searchMonthlyPricesFrom != null && this.$store.state.searchMonthlyPricesTo != null){
                        res = 'Multiple';
                    }else if(this.$store.state.searchMonthlyPricesFrom != null && this.$store.state.searchMonthlyPricesTo == null){
                        if(this.monthlyPricesFrom != null){
                            res = this.monthlyPricesFrom.filter(val => val.id == this.$store.state.searchMonthlyPricesFrom);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }else if(this.$store.state.searchMonthlyPricesFrom == null && this.$store.state.searchMonthlyPricesTo != null){
                        if(this.monthlyPricesTo != null){
                            res = this.monthlyPricesTo.filter(val => val.id == this.$store.state.searchMonthlyPricesTo);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }else if(this.$store.state.searchFinanceDeposit != null ){
                        if(this.financeDeposits != null){
                            res = this.financeDeposits.filter(val => val.id == this.$store.state.searchFinanceDeposit);
                            res = (res != null && res.length == 1) ? res[0].label : '';
                        }
                    }
                    return res;
                },
                formattedLocationLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchLocations.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchLocations.length == 1){
                        if(this.counties != null) {
                            res = this.counties.filter(val => val.id == this.$store.state.searchLocations[0]);
                            res = (res != null && res.length == 1) ? res[0].customLabel : '';
                        }
                    }
                    return res;
                },
                formattedTransmissionTypeLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchTransmissionType.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchTransmissionType.length == 1){
                        if(this.transmissionTypes != null) {
                            res = this.transmissionTypes.filter(val => val.id == this.$store.state.searchTransmissionType[0]);
                            res = (res != null && res.length == 1) ? res[0].customLabel : '';
                        }
                    }
                    return res;
                },
                formattedFuelTypeLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchFuelType.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchFuelType.length == 1){
                        if(this.fuelTypes != null) {
                            res = this.fuelTypes.filter(val => val.id == this.$store.state.searchFuelType[0]);
                            res = (res != null && res.length == 1) ? res[0].customLabel : '';
                        }
                    }
                    return res;
                },
                formattedBodyTypeLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchBodyType.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchBodyType.length == 1){
                        if(this.bodyTypes != null) {
                            res = this.bodyTypes.filter(val => val.id == this.$store.state.searchBodyType[0]);
                            res = (res != null && res.length == 1) ? res[0].customLabel : '';
                        }
                    }
                    return res;
                },
                formattedColourLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchColour.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchColour.length == 1){
                        if(this.colours != null) {
                            res = this.colours.filter(val => val.id == this.$store.state.searchColour[0]);
                            res = (res != null && res.length == 1) ? res[0].customLabel : '';
                        }
                    }
                    return res;
                },
                formattedSeatsLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchSeats.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchSeats.length == 1){
                        if(this.seats != null) {
                            res = this.seats.filter(val => val.id == this.$store.state.searchSeats[0]);
                            res = (res != null && res.length == 1) ? res[0].customLabel : '';
                        }
                    }
                    return res;
                },
                formattedDoorsLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchDoors.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchDoors.length == 1){
                        if(this.doors != null) {
                            res = this.doors.filter(val => val.id == this.$store.state.searchDoors[0]);
                            res = (res != null && res.length == 1) ? res[0].customLabel : '';
                        }
                    }
                    return res;
                },
                formattedSafetyRatingsLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchSafetyRating.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchSafetyRating.length == 1){
                        if(this.safetyRatings != null) {
                            res = this.safetyRatings.filter(val => val.id == this.$store.state.searchSafetyRating[0]);
                            res = (res != null && res.length == 1) ? res[0].customLabel : '';
                        }
                    }
                    return res;
                },
                formattedFuelConsumptionLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchFuelConsumption.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchFuelConsumption.length == 1){
                        if(this.fuelConsumption != null) {
                            res = this.fuelConsumption.filter(val => val.id == this.$store.state.searchFuelConsumption[0]);
                            res = (res != null && res.length == 1) ? res[0].id + ' mpg' : '';
                        }
                    }
                    return res;
                },
                formattedEnvironmentalLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchEnvironmental.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchEnvironmental.length == 1){
                        if(this.environmental != null) {
                            res = this.environmental.filter(val => val.id == this.$store.state.searchEnvironmental[0]);
                            res = (res != null && res.length == 1) ? res[0].customLabel : '';
                        }
                    }
                    return res;
                },
                formattedEnginePowerLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchEnginePower.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchEnginePower.length == 1){
                        if(this.enginePowers != null) {
                            res = this.enginePowers.filter(val => val.id == this.$store.state.searchEnginePower[0]);
                            res = (res != null && res.length == 1) ? res[0].id.toString().replace('_','-') : '';
                        }
                    }
                    return res;
                },
                formattedAccelerationLabel(){
                    let res = 'Any';
                    if(this.$store.state.searchAcceleration.length > 1) {
                        res = 'Multiple';
                    }else if(this.$store.state.searchAcceleration.length == 1){
                        if(this.accelerations != null) {
                            res = this.accelerations.filter(val => val.id == this.$store.state.searchAcceleration[0]);
                            res = (res != null && res.length == 1) ? res[0].id.toString().replace('+','-') + 's' : '';
                        }
                    }
                    return res;
                },
                ...mapState([
                    'makes',
                    'models',
                    'years',
                    'yearsFrom',
                    'yearsTo',

                    'prices',
                    'pricesFrom',
                    'pricesTo',
                    'deposits',
                    'monthlyPrices',
                    'monthlyPricesFrom',
                    'monthlyPricesTo',
                    'locations',
                    'counties',
                    'mileages',
                    'mileagesFrom',
                    'mileagesTo',
                    'kilometres',
                    'kilometresFrom',
                    'kilometresTo',
                    'engineSizes',
                    'engineSizesFrom',
                    'engineSizesTo',
                    'transmissionTypes',
                    'fuelTypes',
                    'bodyTypes',
                    'colours',
                    'seats',
                    'doors',
                    'safetyRatings',
                    'fuelConsumption',
                    'environmental',
                    'enginePowers',
                    'accelerations',
                    'financeDeposits',

                    'searchMake',
                    'searchMakeDesktop',
                    'searchModel',
                    'searchYearFrom',
                    'searchYearTo',
                    'searchPriceFrom',
                    'searchPriceTo',
                    'searchMonthlyPricesFrom',
                    'searchMonthlyPricesTo',
                    'searchLocations',
                    'searchMileageFrom',
                    'searchMileageTo',
                    'searchKilometresFrom',
                    'searchKilometresTo',
                    'searchEngineSizeFrom',
                    'searchEngineSizeTo',
                    'searchTransmissionType',
                    'searchFuelType',
                    'searchBodyType',
                    'searchColour',
                    'searchSeats',
                    'searchDoors',
                    'searchSafetyRating',
                    'searchFuelConsumption',
                    'searchEnvironmental',
                    'searchEnginePower',
                    'searchAcceleration',
                    'searchFinanceDeposit',
                    'total',
                    'resultsLoaded',
                    'searchResults',
                    'urlParams',
                    'isLoading'
                ])
            },

            mounted () {
                this.setToggleReveal();
                this.checkResetFilters();
                this.freeText = this.$store.state.freeText;
                this.freeTextAny = this.$store.state.freeTextAny != null ? this.$store.state.freeTextAny : false;
                this.setManualSortBy(false);
                this.setSearchOrderBy('price-desc');

                let that = this;
                setTimeout(function(){
                    that.searchBlock = false;
                }, 1000)
            },
            filters: {
                capitalize: function (value) {
                    if (!value) return ''
                    value = value.toString()
                    return value.charAt(0).toUpperCase() + value.slice(1)
                },
                formatagg: function(value) {
                    if (!value) return ''
                    value = value.toString()
                    return value.replace(/_/g, ' ')
                },
                formataggdash: function(value) {
                    if (!value) return ''
                    value = value.toString()
                    return value.replace(/_/g, '-')
                }
            },
            methods: {
                ...mapMutations([
                    'setMakes',
                    'setModels',
                    'setYears',
                    'setPrices',
                    'setLocations',
                    'setCounties',
                    'setMileages',
                    'setKilometres',
                    'setEngineSizes',
                    'setTransmissionTypes',
                    'setFuelTypes',
                    'setBodyTypes',
                    'setColours',
                    'setSeats',
                    'setDoors',
                    'setMonthlyPrices',
                    'setSafetyRatings',
                    'setFuelConsumptions',
                    'setEnvironmentals',
                    'setEnginePowers',
                    'setAccelerations',
                    'setFinanceDeposits',
                    'setOpenMobileRefine',
                    'setSearchMake',
                    'setSearchMakeDesktop',
                    'unsetSearchMake',
                    'setSearchModel',
                    'setSearchModelDesktop',
                    'unsetSearchModel',
                    'setSearchYearFrom',
                    'setSearchYearTo',
                    'setSearchPriceFrom',
                    'setSearchPriceTo',
                    'setSearchLocations',
                    'setSearchMileageFrom',
                    'setSearchMileageTo',
                    'setSearchKilometresFrom',
                    'setSearchKilometresTo',
                    'setSearchMonthlyPricesFrom',
                    'setSearchMonthlyPricesTo',
                    'setSearchEngineSizeFrom',
                    'setSearchEngineSizeTo',
                    'setSearchTransmissionType',
                    'setSearchFuelType',
                    'setSearchBodyType',
                    'setSearchColour',
                    'setSearchSeats',
                    'setSearchDoors',
                    'setSearchSafetyRating',
                    'setSearchFuelConsumption',
                    'setSearchEnvironmental',
                    'setSearchEnginePower',
                    'setSearchAcceleration',
                    'setSearchFinanceDeposit',
                    'setCurrentFilter',
                    'setSearchOrderBy',
                    'setRequestedPage',
                    'setManualSortBy',
                    'setSearchFilters',
                    'setJustLoaded',
                    'setUrlParams',
                    'setSearchResults',
                    'setBackendPagination',
                    'setCurrentPage',
                    'setPerPage',
                    'setTotal',
                    'setFrom',
                    'setTo',
                    'setResultsLoaded',
                    'setUrl',
                    'setIsLoading',
                    'resetSearchVars',
                    'setInternalSource'
                ]),
                ...mapActions([
                    'loadFiltersAction',
                    'syncLoadFiltersAction',
                    'performSearchAction',
                    'updateUrlAction',
                    'updateFiltersAction',
                    'populateFiltersAction'
                ]),
                resetFilter(filter){
                    switch (filter){
                        case 'makes':
                            this.$store.commit('setSearchMakeDesktop', []);
                            break;
                        case 'models':
                            this.$store.commit('setSearchModelDesktop', []);
                            break;
                        case 'year':
                            this.$store.commit('setSearchYearFrom', '');
                            this.$store.commit('setSearchYearTo', '');
                            break;
                        case 'price':
                            this.$store.commit('setSearchPriceFrom', null);
                            this.$store.commit('setSearchPriceTo', null);
                            this.$store.commit('setSearchMonthlyPricesFrom', null);
                            this.$store.commit('setSearchMonthlyPricesTo', null);
                            this.$store.commit('setSearchFinanceDeposit', null);
                            this.$store.commit('setMonthlyPriceSwitch', false);
                            break;
                        case 'mileage':
                            this.$store.commit('setSearchMileageFrom', null);
                            this.$store.commit('setSearchMileageTo', null);
                            this.$store.commit('setSearchKilometresFrom', null);
                            this.$store.commit('setSearchKilometresTo', null);
                            break;
                        case 'engine-size':
                            this.$store.commit('setSearchEngineSizeFrom', null);
                            this.$store.commit('setSearchEngineSizeTo', null);
                            break;
                    }
                },
                resetFilters(){
                    if(this.$router.currentRoute.path.replace('/s-1', '') != '/used-cars'){
                        return this.$router.push('/used-cars');
                    }
                },
                setToggleReveal(){
                    $(".refinesearch__menu li div.reveal").click(function (e) {
                        e.preventDefault();
                        const a = $(e.currentTarget).data("target"),
                            i = $('[data-id= ' + a + ']');
                        $(e.currentTarget).hasClass("active") ? $(e.currentTarget).removeClass("active") : $(e.currentTarget).addClass("active"), i.slideToggle();
                    })
                },
                checkResetFilters() {
                    if (typeof this.$router.currentRoute.params.slug === "undefined" || this.$router.currentRoute.params.slug === 's-1') {
                        this.resetSearchVars();
                    }
                },
                async loadOptions() {
                },
                close (filter) {
                    switch (filter) {
                        case 'makes':
                            this.$refs.makes.close();
                            break;
                        case 'models':
                            this.$refs.models.close();
                            break;
                        default:
                            break;
                    }

                },
                orderByChange($event) {
                    // Sets the current filter
                    this.setSearchOrderBy($event.target.value);
                    this.setRequestedPage(1);
                    this.$route.query.page = null;
                    //Set manual Search to true
                    this.setManualSortBy(true);
                    this.search();
                },
                resetPrice(value){
                    if (value == true) {
                        this.setSearchPriceFrom(null);
                        this.setSearchPriceTo(null);
                    } else {
                        this.setSearchMonthlyPricesFrom(null);
                        this.setSearchMonthlyPricesTo(null);
                        this.setSearchFinanceDeposit(null);
                    }
                    this.updateUrlAction();
                    this.updateVueRouter();
                },
                resetMileage(value){
                    if (value == 'mile') {
                        this.setSearchKilometresTo(null);
                        this.setSearchKilometresFrom(null);

                    } else {
                        this.setSearchMileageFrom(null);
                        this.setSearchMileageTo(null);
                    }

                    this.updateUrlAction();
                    this.updateVueRouter();
                },

                resetSearch(){
                    this.setJustLoaded(false);
                    this.setUrlParams({});
                    this.setUrl(process.env.VUE_APP_API_URL + '/api/ad-elastic-filters?');
                },
                freeTextSearch(){
                    //Check if the free text search is set
                    if(this.freeText != null){
                        this.$store.commit('setFreeText', this.freeText);
                        this.$store.commit('setFreeTextAny', this.freeTextAny);
                        this.search();
                    }
                },
                search(obj, currentFilter){
                    if(this.searchBlock){
                        return false;
                    }
                    switch(currentFilter){
                        case 'searchYearFrom':
                            this.setSearchYearFrom(obj);
                            break;
                        case 'searchYearTo':
                            this.setSearchYearTo(obj);
                            break;
                        case 'searchPriceFrom':
                            this.setSearchPriceFrom(obj);
                            break;
                        case 'searchPriceTo':
                            this.setSearchPriceTo(obj);
                            break;
                        case 'searchFinanceDeposits':
                            this.setSearchFinanceDeposit(obj);
                            break;
                        case 'monthlyPricesFrom':
                            this.setSearchMonthlyPricesFrom(obj);
                            break;
                        case 'monthlyPricesTo':
                            this.setSearchMonthlyPricesTo(obj);
                            break;
                        case 'searchMileageFrom':
                            this.setSearchMileageFrom(obj);
                            break;
                        case 'searchMileageTo':
                            this.setSearchMileageTo(obj);
                            break;
                        case 'searchKilometresFrom':
                            this.setSearchKilometresFrom(obj);
                            break;
                        case 'searchKilometresTo':
                            this.setSearchKilometresTo(obj);
                            break;
                        case 'searchEngineSizeFrom':
                            this.setSearchEngineSizeFrom(obj);
                            break;
                        case 'searchEngineSizeTo':
                            this.setSearchEngineSizeTo(obj);
                            break;
                        default:
                            break;
                    }

                    //Internal search
                    this.updateUrlAction();
                    this.updateVueRouter();
                },


                updateVueRouter() {

                    let params = this.$store.state.urlParams;
                    if(params.sortby == 'price-desc'){
                        //Don't put price-desc on the url (default)
                        delete params.sortby;
                    }

                    let newQueryString = '/' + this.$router.currentRoute.name + '/' + Object.keys(params).map(key => key + '-' + this.$store.state.urlParams[key]).join('/');
                    let currentQueryString = this.$router.currentRoute.fullPath;
                    if (newQueryString != currentQueryString) {
                        this.$router.push({path: newQueryString.toLowerCase()});
                    }
                },
                setCurFilter(filter){
                    this.setCurrentFilter(filter);
                    this.clearPagination();
                },

                clearPagination(){
                    this.setRequestedPage(1);
                    this.$route.query.page = null;
                }
            }
        }
    </script>

    <style scoped>

    </style>
